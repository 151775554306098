<template>
  <dx-util-popup
    :ref="materialItemFormPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    height="auto"
    width="800px"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="materialItemFormPopupShown"
    @hidden="materialItemFormPopupHidden"
  >
    <div>
      <dx-util-form id="materialItem" :ref="materialItemFormRef" :form-data="materialItemForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="materialItemForm">
          <dx-util-item :visible="isNewItemAndStockable" template="warningForInventory" />
          <dx-util-item data-field="costGroupId" editor-type="dxSelectBox" :editor-options="costGroupOptions" :label="{text: 'Cost Group'}">
            <dx-util-required-rule message="Cost group is required" />
          </dx-util-item>
          <dx-util-item data-field="title" editor-type="dxTextBox" :label="{text: 'Name'}">
            <dx-util-required-rule message="Name is required" />
          </dx-util-item>
          <dx-util-group-item :col-count="3">
            <dx-util-item :visible="!needPurchase" data-field="finance.cost" editor-type="dxNumberBox" :editor-options="costOptions" :label="{text: 'Cost'}" :help-text="needPurchase ? 'Go to make material purchase for update' : ''">
              <dx-util-required-rule v-if="!needPurchase" message="Cost is required" />
            </dx-util-item>
            <dx-util-item :visible="!isNewItemAndStockable" data-field="finance.salePrice" editor-type="dxNumberBox" :editor-options="salePriceOptions" :label="{text: 'Sale Price'}">
              <dx-util-required-rule message="Sale price is required" />
            </dx-util-item>
            <dx-util-item :visible="needPurchase" data-field="threshold" editor-type="dxNumberBox" :editor-options="thresholdOptions" />
          </dx-util-group-item>
          <dx-util-item :visible="!!materialItemForm.id" data-field="status" editor-type="dxRadioGroup" :editor-options="statusOptions">
            <dx-util-required-rule message="Status is required" />
          </dx-util-item>
          <dx-util-item data-field="description" editor-type="dxTextArea" />
          <template #warningForInventory>
            <div class="text-warning d-flex">
              <p-icon name="bi-exclamation-circle" size="36px" class="mr-1" />
              <h4 class="text-warning">
                To enter cost and sale price of new stockable items, you need to make material purchase. (Aqua button)
              </h4>
            </div>
          </template>
      </dx-util-form>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import prepMaterialService from '@/http/requests/prep-material/prepMaterialService'
import { InventoryTypeEnum } from '@/enums'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    costGroups: {
      type: Array,
      required: true,
    },
    itemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      popupTitle: '',
      materialItemFormPopupRef: 'materialItemFormPopupRef',
      materialItemFormRef: 'materialItemFormRef',
      materialItemForm: {
        id: null,
        costGroupId: null,
        description: '',
        status: 'active',
        threshold: null,
        finance: {
          cost: 0,
          salePrice: 0,
          inventoryType: '',
        },
      },
      needPurchase: false,
    }
  },
  computed: {
    materialItemFormPopup() {
      return this.$refs.materialItemFormPopupRef.instance
    },
    isNewItemAndStockable() {
      let result = false
      if (this.materialItemForm.costGroupId) {
        result = this.costGroups.filter(el => el.value === this.materialItemForm?.costGroupId)[0]?.inventoryType === InventoryTypeEnum.INVENTORY.value
      }
      return !this.itemId && result
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.materialItemFormPopup.hide()
        },
      }
    },
    costGroupOptions() {
      return {
        dataSource: this.costGroups,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
        onSelectionChanged: e => {
          this.needPurchase = e.selectedItem.inventoryType === InventoryTypeEnum.INVENTORY.value
          this.materialItemForm.finance.inventoryType = e.selectedItem.inventoryType
        },
      }
    },
    statusOptions() {
      return {
        dataSource: [
          { text: 'Active', value: 'active' },
          { text: 'Passive', value: 'passive' },
        ],
        valueExpr: 'value',
        displayExpr: 'text',
        layout: 'horizontal',
      }
    },
    costOptions() {
      return {
        format: '$ #,##0.##',
        min: 0,
      }
    },
    salePriceOptions() {
      return {
        format: '$ #,##0.##',
        min: 0,
      }
    },
    thresholdOptions() {
      return {
        format: '#,##0',
        min: 0,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      async handler() {
        if (this.itemId) {
          const result = await prepMaterialService.fetchInfoById(this.itemId)
          this.materialItemForm.id = result.data.id
          this.materialItemForm.title = result.data.title
          this.materialItemForm.costGroupId = result.data.costGroupId
          this.materialItemForm.status = result.data.status
          this.materialItemForm.finance.cost = result.data.finance.cost
          this.materialItemForm.finance.salePrice = result.data.finance.salePrice
          this.materialItemForm.finance.inventoryType = result.data.finance.inventoryType
          this.materialItemForm.description = result.data.description
        } else {
          // To clear all form values, like it is closed
          this.materialItemFormPopupHidden()
        }

        if (this.componentId) {
          this.materialItemFormPopup.show()
        }
      },
    },
    isNewItemAndStockable() {
      this.materialItemForm.finance.cost = 0
      this.materialItemForm.finance.salePrice = 0
    },
  },
  methods: {
    materialItemFormPopupShown() {
      this.popupTitle = this.itemId ? `Edit | ${this.materialItemForm.title}` : 'New Item'
    },
    materialItemFormPopupHidden() {
      this.$nextTick(() => {
        this.$refs.materialItemFormRef.instance.resetValues()
      })
      this.materialItemForm.id = null
      this.materialItemForm.title = ''
      this.materialItemForm.costGroupId = null
      this.materialItemForm.status = 'active'
      this.materialItemForm.finance.cost = 0
      this.materialItemForm.finance.salePrice = 0
      this.materialItemForm.finance.inventoryType = null
      this.materialItemForm.description = ''
    },
    closePopup() {
      this.materialItemFormPopup.hide()
    },
    async handleSubmit() {
      const result = this.$refs.materialItemFormRef.instance.validate()
      if (result.isValid) {
        if (this.materialItemForm?.id > 0) {
          await prepMaterialService.update(this.materialItemForm)
        } else {
          await prepMaterialService.create(this.materialItemForm)
        }
        this.$emit('emit-form-saved')
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss">
#materialItem {
  .dx-field-item-help-text {
    font-size: 12px;
    color: #f49c0f;
  }
}
</style>
